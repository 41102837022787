import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import ImageIcon from "@mui/icons-material/Image";
import DownloadIcon from "@mui/icons-material/Download";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Checkbox,
  Box,
  InputAdornment,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  CircularProgress,
  TableSortLabel,
  Select,
  MenuItem,
  Stack,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import { useDispatch, useSelector } from "react-redux";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import configServ from "../../../services/config";
import SearchBox from "../../Search/SearchBox";
import Constants from "../../../utility/Constants";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateSelector from "../../DateSelector/DateSelector";
import useDebounce from "../../hooks/useDebounce";
import { useLocation, useNavigate } from "react-router-dom";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import VoucherOrientation from "../../Voucher/VoucherOrientation";
import UploadImage from "../../Upload/UploadImage";

import useDeviceDetect from "../../Detect/useDeviceDetect ";
import LoaderNew from "../../Loader/LoaderNew";

//FUNCTION
export default function VoucherTable({ handleEditData, isChanged, isEdit }) {
  //#region code
  const navigate = useNavigate();
  const {
    user_id,
    company_id,
    is_super,
    companyName,
    companyAddress,
    companyState,
    companyCountry,
  } = useSelector((state) => state.GlobalVariables);
  const dispatch = useDispatch();
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [voucherList, setVoucherList] = useState([]);
  const [accountClassified, setAccountClassified] = useState([]);
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [searchBy, setSearchBy] = useState("customer_name");
  const [count, setCount] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("customer_name");
  const [loading, setLoading] = useState(true);
  const [fromDate, SetFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isPrint, setIsPrint] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);
  const [printData, setPrintData] = useState({});
  const [openImg, setOpenImg] = useState(false);
  const [Img, setImg] = useState("");
  const location = useLocation();
  const { entity_number, entity } = location.state || {};
  const [redirectVoucherNo, setRedirectVoucherNo] = useState(entity_number);
  const [entity_type, setEntity_type] = useState(entity ? entity : "customer");

  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    if (redirectVoucherNo) {
      setSearchBy("voucher_no");
      setSearch(redirectVoucherNo);
      fetchVoucherList("voucher_no", redirectVoucherNo);
    } else {
      setSearchBy("customer_name");
      setSearch("");
    }
  }, [redirectVoucherNo]);

  useEffect(() => {
    if (!redirectVoucherNo) {
      fetchVoucherList();
    }
  }, [isChanged, page, rowsPerPage, order, orderBy]);

  useEffect(() => {
    if (!redirectVoucherNo) {
      if (page === 0) {
        fetchVoucherList();
      } else {
        setPage(0);
      }
    }
  }, [debounceSearch, entity_type]);

  useEffect(() => {
    if (fromDate && toDate) {
      fetchVoucherList();
    }
  }, [fromDate, toDate]);

  const fetchVoucherList = async (searchByVch = null, VchNo = null) => {
    try {
      setLoading(true);
      const dataToSend = {
        is_super: is_super,
        user_id: user_id,
        company_id: company_id,
        search_by: searchByVch ? searchByVch : searchBy,
        search: VchNo ? VchNo : debounceSearch,
        per_page: rowsPerPage,
        page: page,
        sort_by: orderBy,
        order,
        entity_type: entity_type,
        start_date: fromDate,
        end_date: toDate,
      };
      const res = await configServ.getVouchers(dataToSend);
      if (res.status === 200) {
        setVoucherList(res.data);
        setCount(res.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const headers = [
    { id: "fname", label: "Account" },
    { id: "voucher_no", label: "Voucher" },
    { id: "voucher_type", label: "Type" },
    { id: "date", label: "Date" },
    { id: "amount", label: "Amount" },
  ];

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const bodyCellStyle = {
    py: 0,
    my: 1,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (params) => {
    const data = voucherList.filter((x) => x.id === params);
    handleEditData(data);
  };

  const handleImage = (params) => {
    try {
      const data = voucherList.find((x) => x.id === params);
      if (Boolean(data.is_image) === true) {
        const image = `data:image/${data.extension};base64,${data.image}`;
        setOpenImg(true);
        setImg(image);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEntityType = (params) => {
    try {
      const { name, value } = params.target;
      setEntity_type(value);
    } catch (e) {
      console.log(e);
    }
  };

  const cashVoucherRef = useRef();

  const handlePrintData = () => {
    setIsPrint(true);
  };

  useEffect(() => {
    if (isPrint === true) {
      handlePrint();
      setIsPrint(false);
    }
  }, [isPrint]);

  const handlePrint = useReactToPrint({
    content: () => cashVoucherRef.current,
    documentTitle: "Cash Vouchers",
  });

  const handleDownloadData = () => {
    setIsDownload(true);
  };

  useEffect(() => {
    if (isDownload === true) {
      handleDownload();
      setIsDownload(false);
    }
  }, [isDownload]);

  const handleDownload = () => {
    try {
      dispatch(triggerLoader());
      const input = cashVoucherRef.current;

      html2canvas(input, { scale: 1 }) // Adjust the scale to reduce resolution (e.g., 1 for lower resolution)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg", 0.75); // Use 'image/jpeg' and reduce quality (0.75 is 75% quality)
          const pdf = new jsPDF();
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight); // Use 'JPEG' instead of 'PNG'
          setTimeout(() => {
            pdf.save("CashVouchers.pdf");
            dispatch(triggerLoader());
          }, 5000);
        })
        .catch((error) => {
          console.error("Error generating PDF: ", error);
          dispatch(triggerLoader()); // Stop loader if there's an error
        });
    } catch (e) {
      console.log(e);
      dispatch(triggerLoader()); // Stop loader if there's an error in try block
    }
  };

  const handleCustomerClick = (params) => {
    try {
      const data = params;
      navigate("/account-master-entry/customers", { state: data });
    } catch (e) {
      console.log(e);
    }
  };

  const printVoucher = async (voucher) => {
    const data = {
      id: voucher.id,
      voucher_no: voucher.voucher_no,
      entity_id: voucher.entity_id,
      user_id: user_id,
      company_id: company_id,
    };
    setPrintOpen(true);
    setPrintData(data);
  };

  const handleVoucherPrint = (params) => {
    const data = voucherList.filter((x) => x.id === params.id);
    printVoucher(data[0]);
  };

  const handleCloseImg = () => {
    setOpenImg(false);
  };

  //#endregion

  return (
    <Paper sx={{ width: "100%" }}>
      <UploadImage open={openImg} handleClose={handleCloseImg} Img={Img} />

      <VoucherOrientation
        open={printOpen}
        setOpen={setPrintOpen}
        data={printData}
      />

      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ mb: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid container item xs={12} md={6} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Voucher List: {count}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent={{ xs: "center", md: "flex-end" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              onClick={handleDownloadData}
              startIcon={<DownloadIcon sx={{ ml: 2 }} />}
              title="Download"
              sx={{
                backgroundColor: "#4bcf6b",
                color: "white",

                "&:hover": {
                  backgroundColor: "#00b92f",
                  color: "white",
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handlePrintData}
              startIcon={<PrintIcon sx={{ ml: 2 }} />}
              title="Print"
              sx={{
                backgroundColor: "#349af1",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0868F8",
                  color: "white",
                },
              }}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          justifyContent={"flex-start"}
          sx={{
            mt: { xs: 2, md: 0 }, // Add margin top for mobile devices
          }}
        >
          <FormControl fullWidth size="small">
            <InputLabel>Account Type</InputLabel>
            <Select
              fullWidth
              size="small"
              variant="standard"
              sx={{ background: "white" }}
              label={"Type"}
              name="entity_type"
              value={entity_type}
              onChange={handleEntityType}
            >
              {Constants.EntityTypes.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0} md={5}>
          {/* <DateSelector startDate={SetFromDate} endDate={setToDate} /> */}
        </Grid>
        {redirectVoucherNo ? (
          <>
            <Grid container item xs={12} md={5} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                title="Referesh"
                color="success"
                startIcon={<RefreshIcon sx={{ ml: 1, color: "white" }} />}
                onClick={() => {
                  setRedirectVoucherNo(null);
                }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={2} sx={{ mt: { xs: 1, md: 0 } }}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel
                    variant="standard"
                    htmlFor="uncontrolled-native"
                    sx={{ ml: { xs: 2, md: 0 } }}
                  >
                    Search By
                  </InputLabel>
                  <NativeSelect
                    defaultValue={"customer_name"}
                    onChange={handleSearchBy}
                    inputProps={{
                      name: "searchBy",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={"customer_name"}>Account</option>
                    <option value={"voucher_no"}>Voucher No</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} justifyContent={"flex-end"}>
              <SearchBox search={search} setSearch={setSearch} />
            </Grid>
          </>
        )}
      </Grid>

      {isMobile ? (
        <>
          <Grid container alignItems={'center'} spacing={1} mt={1}>
            <Grid container item xs={6} md={6} justifyContent={'center'}>
              <FormControl fullWidth size='small'>
                <InputLabel>Sort By</InputLabel>
                <Select
                  fullWidth
                  size='small'
                  name='order_by'
                  label='Sort By'
                  value={orderBy}
                  onChange={(e) => setOrderBy(e.target.value)}
                  sx={{ background: 'white' }}
                >
                  {headers.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container item xs={6} md={6} justifyContent={'center'}>
              <FormControl fullWidth size='small'>
                <InputLabel>Order</InputLabel>
                <Select
                  fullWidth
                  size='small'
                  name='order'
                  label='Order'
                  value={order}
                  onChange={(e) => setOrder(e.target.value)}
                  sx={{ background: 'white' }}
                >
                  <MenuItem key={1} value={'asc'}>
                    {'Ascending'}
                  </MenuItem>
                  <MenuItem key={2} value={'desc'}>
                    {'Descending'}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ py: 2 }}>
            {loading ? (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <LoaderNew />
              </Grid>
            ) : voucherList.length > 0 ? (
              voucherList.map((row, index) => (
                <Grid item xs={12} key={row.id}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                      {row.fname} -{" "}
                      <span
                        style={{
                          fontSize: "18px", // Adjust this size as needed (0.875rem is equivalent to 14px)
                          color: "gray", // A darker shade of text, you can adjust the hex code to your preference
                        }}
                      >
                        {row.address}
                      </span>
                    </Typography>

                    <Typography variant="body2" sx={{ color: "gray" }}>
                      <span style={{ fontWeight: "bold", color: "black" }}>
                        Voucher No:
                      </span>{" "}
                      {row.voucher_no}
                    </Typography>

                    <Typography variant="body2" sx={{ color: "gray" }}>
                      <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        Type:
                      </span>{" "}
                      {row.voucher_type}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        Date:
                      </span>{" "}
                      {CheckAndReturn.transformToDate3(row.date)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      <span style={{ fontWeight: "bold", color: "black" }}>
                        {" "}
                        Amount:
                      </span>{" "}
                      ₹{row.amount}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                      <Tooltip title="Edit" placement="top">
                        <IconButton onClick={() => handleEdit(row.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Image" placement="top">
                        <IconButton
                          disabled={!Boolean(row.is_image)}
                          onClick={() => handleImage(row.id)}
                        >
                          <ImageIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Print" placement="top">
                        <IconButton onClick={() => handleVoucherPrint(row)}>
                          <PrintTwoToneIcon />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Paper>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Box sx={{ color: "red", fontWeight: 600 }}>No data found.</Box>
              </Grid>
            )}
          </Grid>
          {!loading && voucherList.length > 0 && (
            <Box>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh", // Set full height
              // display: { xs: "none", sm: "none", lg: "block" },
            }}
            ref={cashVoucherRef}
          >
            {(isPrint || isDownload) && (
              <>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  {companyName}
                </Typography>
                <Typography sx={{ textAlign: "center" }}>
                  {companyAddress}, {companyState}, {companyCountry}
                </Typography>
              </>
            )}
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>S.No.</TableCell>
                  {headers.map((column) => (
                    <TableCell
                      key={column.id}
                      sortDirection={orderBy === column.id ? order : false}
                      sx={headerCellStyle}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell sx={headerCellStyle}>Action</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                    <div>
                      <LoaderNew />
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                <TableBody>
                  {voucherList.length > 0 ? (
                    voucherList.map((row, index) => {
                      return (
                        <TableRow
                          key={row.id}
                          sx={{
                            ...rowStyle,
                            ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                          }}
                        >
                          <TableCell sx={bodyCellStyle}>
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                          <TableCell sx={bodyCellStyle}>
                            {entity_type === "customer" ? (
                              <span
                                className="linkStyle"
                                onClick={() => handleCustomerClick(row.fname)}
                              >
                                {row.fname}
                              </span>
                            ) : (
                              <span>{row.fname}</span>
                            )}
                            -
                            <span style={{ color: "gray" }}>{row.address}</span>
                          </TableCell>
                          <TableCell sx={bodyCellStyle}>
                            {row.voucher_no}
                          </TableCell>
                          <TableCell sx={bodyCellStyle}>
                            {row.voucher_type}
                          </TableCell>
                          <TableCell sx={bodyCellStyle}>
                            {CheckAndReturn.transformToDate3(row.date)}
                          </TableCell>
                          <TableCell sx={bodyCellStyle}>
                            ₹{row.amount}
                          </TableCell>
                          <TableCell sx={bodyCellStyle}>
                            <Stack direction={"row"}>
                              <Tooltip title="Edit" placement="top">
                                <IconButton onClick={() => handleEdit(row.id)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit" placement="top">
                                <IconButton
                                  disabled={!Boolean(row.is_image)}
                                  onClick={() => handleImage(row.id)}
                                >
                                  <ImageIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Print" placement="top">
                                <IconButton
                                  onClick={() => handleVoucherPrint(row)}
                                >
                                  <PrintTwoToneIcon />
                                </IconButton>
                              </Tooltip>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                        <Box sx={{ color: "red", fontWeight: 600 }}>
                          No data found.
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Paper>
  );
}
