import React, { useState, useEffect } from "react";
import Footer from "../../../Components/Footer/Footer";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import CustHeader from "../../../Components/header/Header/CustHeader";
import AddInvoiceToPay from "./AddInvoiceToPay";
import Skeleton from "@mui/material/Skeleton";
import {
  Box,
  Stack,
  Paper,
  Divider,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import { Autocomplete } from "@mui/joy";
import configServ from "../../../services/config";
import MoneyIcon from "@mui/icons-material/Money";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import { MultiSelect } from "react-multi-select-component";

const Pay = () => {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const [invoicelist, setInvoiceList] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [totalDue, setTotalDue] = useState(0);
  const [paySource, setPaySource] = useState(["cash", "bank"]);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    pay_against: "invoice_pay",
    payment_mode: "", // Initialize to the first payment source or empty
  });
  const [customer, setCustomer] = useState({});
  const [invoiceToPay, setInvoiceToPay] = useState([
    {
      id: 1,
      invoice_id: 0,
      invoice_no: "",
      due: 0,
      amountPaying: 0,
    },
  ]);
  const [totalAmountToPay, setTotalAmountToPay] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [invoice, setInvoice] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };
  const handleAddItem = () => {
    setInvoiceToPay((state) => [
      ...state,
      {
        id: invoiceToPay[invoiceToPay.length - 1].id + 1,
        invoice_no: "",
        due: 0,
        amountPaying: 0,
      },
    ]);
  };

  // Define the function to handle invoice selection and calculate total amounts
  const handleInvoiceSelection = (selected) => {
    if (selected.length === 0) {
      // Clear the selected invoice
      setSelectedInvoices("");
      setInvoiceAmount(0);
      setTotalAmount(0);
      setTotalAmountToPay(0);
    } else {
      const lastSelectedInvoice = selected[0];
      const selectedInvoiceNumber = lastSelectedInvoice.value;

      const selectedInvoiceDetail = invoice.find(
        (inv) => inv.invoice_number === selectedInvoiceNumber
      );

      if (selectedInvoiceDetail) {
        const totalAmount = Number(selectedInvoiceDetail.total_amount);

        setSelectedInvoices(selectedInvoiceNumber); // Set as a single string
        setInvoiceAmount(totalAmount);
        setTotalAmount(totalAmount);
        setTotalAmountToPay(totalAmount);
      }
    }
  };

  const delItem = (id) => {
    const updatedItemList = invoiceToPay.filter((item) => item.id !== id);
    setInvoiceToPay(updatedItemList);
    // console.log(updatedItemList)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "pay_against") {
      // Reset form data and states based on the selected option
      setFormData({
        pay_against: value,
        payment_mode: "",
      });
      setSelectedInvoices();
      setInvoiceAmount(0);
      setTotalAmount(0);
      setTotalAmountToPay(0);
    } else {
      // Update other form data fields normally
      setFormData((state) => ({
        ...state,
        [name]: value,
      }));
    }
  };

  const fetchCustomerDetails = async () => {
    try {
      setLoader(true);
      const result = await configServ.getCustomerById({ customer_id: user_id });
      setCustomer(result.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const fetchInvoiceList = async () => {
    try {
      const result = await configServ.GetUnpaidCustomerInvoiceList({
        user_id: user_id,
      });
      // console.log(result)
      setInvoice(result.data);
    } catch (err) {
      console.log(err);
    }
  };

  const amountToPayCalculation = (e) => {
    let total = 0;
    if (formData.pay_against === "invoice_pay") {
      invoiceToPay.map((item) => {
        if (isNaN(item.amountPaying)) {
          return;
        }
        total = total + parseFloat(item.amountPaying.toFixed(2));
      });
      setTotalAmountToPay(total);
    } else {
      setTotalAmountToPay(parseFloat(e.target.value));
    }
  };

  useEffect(() => {
    amountToPayCalculation();
  }, []);

  useEffect(() => {
    fetchInvoiceList();
    fetchCustomerDetails();
  }, []);

  const handleSubmit = async () => {
    if (!formData.payment_mode) { // Validation check
      handleOpenDialog("Please select a payment mode"); // Show alert
      return; // Prevent submission
    }else if (!totalAmountToPay) {
      handleOpenDialog("Please enter a valid amount to pay");
      return;
    } else {
      const dataToSend = {
        customer_id: user_id,
        amount: totalAmountToPay,
        invoice_numbers: selectedInvoices,
        ...formData, // Ensure formData contains required fields
        company_id: company_id, // Ensure company_id is valid
      };
      console.log(dataToSend);
      try {
        const result = await configServ.createCustomerUnregisterPayment(
          dataToSend
        );

        // Check if the status is 200
        if (result.status === 200) {
          handleOpenDialog("Payment successful");
        } else {
          handleOpenDialog("Payment failed");
        }

        clear();
      } catch (err) {
        console.log(err);
        handleOpenDialog("Payment failed");
      }
    }
  };

  const handleInvoiceToPay = (data, id) => {
    const updatedInvoiceToPay = invoiceToPay.map((item) => {
      return item.id === id
        ? {
            ...item,
            invoice_id: data.invoice.id,
            invoice_no: data.invoice.invoice_number,
            due: data.balance,
          }
        : item;
    });
    setInvoiceToPay(updatedInvoiceToPay);
  };

  const clear = () => {
    setFormData({
      pay_against: "invoice_pay",
    });
    setSelectedInvoices();
    setInvoiceAmount(0);
    setTotalAmount(0);
    setTotalAmountToPay(0);
  };

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <Grid container spacing={2}>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#333333aa",
          }}
        >
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <b>Customer Name :</b>&nbsp;
            {loader ? (
              <Skeleton
                variant="text"
                width={230}
                sx={{ display: "inline-block" }}
              />
            ) : (
              <span>{customer.contact_name}</span>
            )}
          </Typography>
        </Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#333333aa",
          }}
        >
          <FormControl>
            <RadioGroup
              row
              name="pay_against"
              onChange={handleChange}
              value={formData.pay_against}
            >
              <FormControlLabel
                value="invoice_pay"
                control={<Radio size="small" />}
                label="Pay against invoice(s)"
              />
              <FormControlLabel
                value="no_invoice_pay"
                control={<Radio size="small" />}
                label="Pay without invoice"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Divider sx={{ backgroundColor: "#333", width: "100%", my: 3 }} />

        {formData.pay_against === "invoice_pay" && (
          <Grid item xs={12}>
            <Stack
              spacing={1}
              sx={{
                width: "100%",
                mb: 2,
                p: 2, // Padding for spacing from screen edges
                backgroundColor: "#f0f4ff", // Light blue background color
                borderRadius: 2, // Rounded corners for a professional look
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: { xs: "block", md: "flex" },
                  gap: 2, // Extra gap between grid items
                }}
              >
                {/* Select Invoice(s) - Multi-select Dropdown */}
                <Grid item xs={12} md={4}>
                  <MultiSelect
                    options={invoice.map((inv) => ({
                      label: inv.invoice_number,
                      value: inv.invoice_number,
                    }))}
                    value={
                      selectedInvoices
                        ? [{ label: selectedInvoices, value: selectedInvoices }]
                        : []
                    }
                    onChange={(selected) => {
                      // Handle both selection and clearing
                      if (selected.length === 0) {
                        handleInvoiceSelection([]); // Pass an empty array if selection is cleared
                      } else {
                        handleInvoiceSelection(selected.slice(-1)); // Keep only the last selected item
                      }
                    }}
                    labelledBy="Select Invoice(s)"
                    hasSelectAll={false}
                    style={{
                      borderRadius: "4px",
                      borderColor: "#d0d0d0",
                    }}
                  />
                </Grid>

                {/* Invoice Amount */}
                <Grid item xs={12} md={3}>
                  <TextField
                    label="Invoice Amount"
                    fullWidth
                    size="small"
                    value={invoiceAmount}
                    InputProps={{
                      readOnly: true, // Make it read-only since it's auto-calculated
                      sx: { backgroundColor: "white", borderRadius: "4px" }, // White background
                    }}
                  />
                </Grid>

                {/* Total Amount */}
                <Grid item xs={12} md={3}>
                  <TextField
                    size="small"
                    label="Total Amount"
                    fullWidth
                    value={totalAmount}
                    InputProps={{
                      readOnly: true, // Make it read-only since it's auto-calculated
                      sx: { backgroundColor: "white", borderRadius: "4px" }, // White background
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Grid>
        )}

        {formData.pay_against === "no_invoice_pay" && (
          <Grid item xs={12} md={4}>
            <Stack
              spacing={1}
              sx={{
                width: "100%",
                mb: 2,
                p: 2, // Padding for spacing from screen edges
                backgroundColor: "#f0f4ff", // Light blue background color
                borderRadius: 2, // Rounded corners for a professional look
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
              }}
            >
              <TextField
                label="Amount To Pay"
                type="number"
                size="small"
                fullWidth
                sx={{ backgroundColor: "white", borderRadius: "4px" }} // Corrected syntax for sx prop
                onChange={amountToPayCalculation}
              />
            </Stack>
          </Grid>
        )}
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Typography
            variant="body"
            sx={{
              color: "#333333aa",
            }}
          >
            <b>Total amount to pay :</b> {totalAmountToPay}
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={6}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <FormControl fullWidth size="small">
            <InputLabel>Payment mode</InputLabel>
            <Select
              required
              name="payment_mode" // Make sure this matches the formData key
              label="Payment mode"
              value={formData.payment_mode || ""} // Bind directly to formData.pay_source
              onChange={(e) =>
                setFormData({ ...formData, payment_mode: e.target.value })
              } // Update formData.pay_source directly
            >
              {paySource.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
          }}
        >
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            Proceed
          </Button>
        </Grid>
      </Grid>
      {/* <Footer /> */}
    </>
  );
};
export default Pay;
