import React, { memo, useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-hot-toast";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { useDispatch, useSelector } from "react-redux";
import configServ from "../../../services/config";
import AlertDialog from "../../AlertDialog/AlertDialog";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import useDeviceDetect from "../../Detect/useDeviceDetect ";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SalesOrderPopUp from "./SalesOrderPopUp";
import Swal from "sweetalert2";
import { rSetShowFab } from "../../../redux/reducers/GlobalVariables";
import Calculation from "../../../utility/Calculations";
import { useNavigate } from "react-router-dom";
import LoaderNew from "../../Loader/LoaderNew";
import DispatchPopup from "../Dispatch/DispatchPopup";
import DispatchSaleOrder from "../Dispatch/DispatchSaleOrder";

//FUNCTION
const SalesOrderTable = ({ isChanged, handleEditData, pagination = true }) => {
  //#region code
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company_id, user_id, is_super, is_admin } = useSelector((state) => state.GlobalVariables);
  const { company_type } = useSelector((state) => state.admin);

  const rowsPerPageOptions = [5, 20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [count, setCount] = useState(0);
  const [searchBy, setSearchBy] = useState("partyname");
  const [isApproved, setIsApproved] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [orderDataList, setOrderDataList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [open, setOpen] = useState(false);
  const [openGstDialog, setOpenGstDialog] = useState(false);
  const currentDate = Calculation.GetCurrentDate();
  // isapproved part
  // Add a state to manage selected checkboxes
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [openDispatch, setOpenDispatch] = useState(false);
  const [dispatchDetail, setDispatchDetail] = useState(null);

  //const dateRef = useRef(null);
  const remarksRef = useRef(null);

  // Handle individual checkbox change
  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle 'Select All' checkbox change
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]); // Unselect all
    } else {
      setSelectedRows(paginatedData.map((row) => row.id)); // Select all IDs
    }
    setSelectAll(!selectAll);
  };

  // Save selected data
  const handleSaveSelected = async () => {
    setLoading(true);
    const dateValue = selectedDate; //dateRef.current.value;

    // Check if the date is filled
    if (!dateValue) {
      toast.error("Please select a date before submitting.");
      return; // Stop the function execution if the date is not provided
    }

    const remarksValue = remarksRef.current.value || null;

    // Filter the selected rows and create the update object
    const selectedData = selectedRows
      .map((selectedId) => {
        const currentRow = paginatedData.find((row) => row.id === selectedId);

        if (currentRow) {
          return {
            id: currentRow.id, // Use the selected row's ID
            updated_at: dateValue, // Use the selected date
            remarks: remarksValue, // Use the remarks, or null if empty
          };
        }
        return null;
      })
      .filter((update) => update !== null); // Filter out any null entries

    if (selectedData.length > 0) {
      const dataToSend = {
        company_id: company_id,
        user_id: user_id,
        sales: selectedData,
      };

      try {
        // Assuming you're using a service to send the data to the API
        const res = await configServ.approveSaleOrders(dataToSend);
        if (res.status === 200) {
          toast.success("Selected data updated successfully!");
          // Refresh or fetch the updated data
          fetchSalesOrderList();
          handleClose(); // Close the dialog after successful submission
        } else {
          toast.error("An error occurred while updating the data.");
          console.error("Failed to update selected data", res);
        }
      } catch (error) {
        console.error("An error occurred while saving selected data", error);
        toast.error("An error occurred while saving the data.");
      } finally {
        setOpenGstDialog(false);
        setLoading(false);
      }

      // Clear the selection after saving
      setSelectedRows([]); // Clear selected rows
      setSelectAll(false); // Reset select all state
    } else {
      toast.error("No rows selected.");
    }
  };

  // Model Started
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const handleClickOpen = () => {
    dispatch(rSetShowFab(false));
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        setOpenGstDialog(true); // Updated to use new state
        // Keep showFab false if confirmed
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
        dispatch(rSetShowFab(true)); // Set showFab to true when 'Don't save' is clicked
      } else if (result.isDismissed) {
        // Handle the case where the dialog is dismissed (cancelled)
        dispatch(rSetShowFab(true));
      }
    });
  };

  const handleClose1 = () => {
    setSelectedDate(currentDate);
    setOpenGstDialog(false); // Updated to use new state
  };
  // model end

  // isApproved end

  const [selectedProductData, setSelectedProductData] = useState([]);
  // handleVisibilityIcon started
  const handleVisibilityIcon = (row) => {
    // Assuming row.products contains the product details for that specific row
    const filteredProducts = row.items; // Filter the specific product
    setSelectedProductData(filteredProducts); // Set the filtered product data
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  const headers = [
    "CUSTOMER",
    "ORDER",
    "DATE",
    "AMOUNT",
    "STATUS",
    ...(company_type === "product"
      ? ["DISPATCH"]
      : []),
    ...(Boolean(is_admin) ? ["APPROVED"] : []), // Conditionally add "Is Approved" if is_admin is true
    "ACTION",
  ];

  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    fetchSalesOrderList();
  }, [isChanged, isApproved]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? orderDataList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : orderDataList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const fetchSalesOrderList = async () => {
    try {
      setLoading(true);

      const dataToSend = {
        company_id: company_id,
        user_id: user_id,
        isApproved: isApproved,
        is_admin: is_admin,
      };

      const res = await configServ.salesOrderList(dataToSend);
      if (res.length > 0) {
        const result = res.map((item) => ({
          ...item,
          dispatch: item.dispatch ? (Boolean(item.dispatch.dispatch) === true ? true : false) : false,
        }));

        setOrderDataList(result);
        setOrderList(result);
        setCount(result.length);
      }
      else {
        setOrderDataList([]);
        setOrderList([]);
        setCount(0);
      }
    }
    catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIsApprovedChange = () => {
    setIsApproved(!isApproved);
    if (!isApproved) {
      setSelectAll(false); // Reset "Select All" if "Is Approved" is checked
      setSelectedRows([]); // Clear selected rows when "Is Approved" is checked
    }
  };

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === "") {
        data = orderList;
      } else if (searchBy === "partyname") {
        data = orderList.filter((x) =>
          x.partyname.toLowerCase().includes(search.toLowerCase())
        );
      }
      setOrderDataList(data);
      setCount(data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrint = (params) => {
    printOrder(params);
  };

  const printOrder = async (order_id) => {
    const data = {
      order_id: order_id,
    };
    dispatch(triggerLoader());
    try {
      const result = await configServ.printSalesOrder(data);
      const blobFile = new Blob([result], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blobFile);
      window.open(url);
    } catch (e) {
      console.log(e);
      handleOpenDialog("Print failed");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handleDate = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleOrderToInvoice = (params) => {
    try {
      const data = params;
      navigate("/invoice", { state: { orderToInvoice: data } });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDispatch = (params) => {
    try {
      const data = params;
      setDispatchDetail(data);
      openDispatchPopup();
    } catch (e) {
      console.log(e);
    }
  };

  const openDispatchPopup = () => {
    setOpenDispatch(true);
  };

  const closeDispatchPopup = () => {
    fetchSalesOrderList();
    setOpenDispatch(false);
    setDispatchDetail(null);
    
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />

      {/* Dispatch */}
      <DispatchSaleOrder
        openDispatch={openDispatch}
        closeDispatchPopup={closeDispatchPopup}
        dispatchDetail={dispatchDetail}
      />

      {/* Modal/Dialog for Product Details */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Product Details</DialogTitle>
        <SalesOrderPopUp
          handleClose={handleClose}
          selectedProductData={selectedProductData}
        />
      </Dialog>

      {/* search starts here */}
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        style={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid
          item
          xs={12}
          md={2}
          display="flex"
          alignItems="center"
          justifyContent={"flex-start"}
          flexWrap="wrap"
        >
          <Typography
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: "bold",
              //mr: 2,
            }}
          >
            Sales Orders: {count}
          </Typography>
        </Grid>

        <Grid item xs={12} md={2}>
          {" "}
          {/* Reduce width for "Search By" */}
          <Box sx={{ minWidth: 100 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"partyname"}>Customer</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "grey" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isApproved}
                onChange={handleIsApprovedChange}
              />
            }
            label="Is Approved"
            sx={{
              //ml: 2,
              fontSize: { xs: "14px", md: "10px" },
            }}
          />

          {selectedRows.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
              sx={{
                //ml: { xs: 2, md: 2 },
                width: { xs: "auto", md: "auto" },
                //padding: { xs: "6px 16px", md: "6px 20px" },
                textTransform: "none",
                lineHeight: 1.5,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Approve
            </Button>
          )}
        </Grid>

        {/* Select All Checkbox */}
        {/* Conditionally render the "Select All" checkbox if "Is Approved" is not checked */}
        {((!isApproved) && (Boolean(is_admin)) && (orderList.length > 0) === true) && (
          <Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              }
              label="Select All"
              sx={{
                ml: 2,
                fontSize: { xs: "14px", md: "16px" },
              }}
            />
          </Grid>
        )}
        {/* <Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              }
              label="Select All"
              sx={{
                ml: 2,
                fontSize: { xs: "14px", md: "16px" },
              }}
            />
          </Grid> */}
      </Grid>

      {/* search ends here */}

      {isMobile ? (
        <>
          {" "}
          <Grid container spacing={2}>
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={2}
              >
                <LoaderNew />
              </Box>
            ) : orderDataList.length > 0 ? (
              paginatedData.map((row, rowIndex) => (
                <Grid item xs={12} key={rowIndex}>
                  <Card sx={{ p: 2, mb: 2 }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {page * rowsPerPage + rowIndex + 1}. {row.partyname}
                      </Typography>
                      <Typography>
                        <strong>Order No: </strong> {row.order_no}
                      </Typography>
                      <Typography>
                        <strong>Order Date: </strong>
                        {CheckAndReturn.transformToDate2(
                          row.order_date
                        ).toString() || "N/A"}
                      </Typography>
                      <Typography>
                        <strong>Total Amount: </strong> ₹{row.total_amount}
                      </Typography>
                      <Typography>
                        <strong> Status: </strong> {row.order_status}
                      </Typography>
                      {
                        (company_type === "product") &&
                        <Typography>
                          <strong>Dispatch: </strong>
                          {
                            Boolean(row.is_dispatch) === true ?
                              <span style={{ color: 'green' }}>dispatched</span>
                              :
                              <span style={{ color: 'red' }}>pending</span>
                          }
                        </Typography>
                      }
                    </CardContent>
                    <CardActions>
                      <Tooltip title="Print" placement="top">
                        <IconButton onClick={() => handlePrint(row.id)}>
                          <PrintTwoToneIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          disabled={
                            (row.order_status === "processing" &&
                              Boolean(is_admin) === false) ||
                            row.order_status === "processed"
                          }
                          onClick={() => handleEditData(row)}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="See Details" arrow>
                        <IconButton onClick={() => handleVisibilityIcon(row)}>
                          <VisibilityIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Create Invoice" arrow>
                        <IconButton
                          onClick={() => handleOrderToInvoice(row)}
                          disabled={!((row.order_status === "processing") && Boolean(is_admin))}
                        >
                          <ReceiptIcon />
                        </IconButton>
                      </Tooltip>
                      {
                        ((row.order_status === 'processed') && Boolean(is_admin) && (company_type === "product")) === true
                        &&
                        <Tooltip>
                          <IconButton color="warning" onClick={() => handleDispatch(row)} disabled={Boolean(row.is_dispatch)}>
                            <LocalShippingIcon />
                          </IconButton>
                        </Tooltip>
                      }
                    </CardActions>
                    <CardActions align="left">
                      <Checkbox
                        disabled={isApproved || Boolean(!is_admin)}
                        checked={
                          isApproved === false
                            ? selectedRows.includes(row.id)
                            : true
                        }
                        onChange={() => handleCheckboxChange(row.id)}
                      />
                      <label htmlFor="">Approve</label>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={12}>
                  <Box textAlign={"center"}>
                    <Typography variant="body1" color={"red"}>
                      No data found.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </Grid>
          {pagination && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </>
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100vh", // Set full height
              // display: { xs: "none", sm: "none", lg: "block" },
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={headerCellStyle}>S.No.</TableCell>
                  {headers.map((header, index) => (
                    <TableCell key={index} style={headerCellStyle}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                      <div>
                        <LoaderNew />
                      </div>
                    </TableCell>
                  </TableRow>
                ) : orderDataList.length > 0 ? (
                  paginatedData.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell sx={{ py: 0 }}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                      <TableCell sx={{ py: 0 }}>{row.partyname}</TableCell>
                      <TableCell sx={{ py: 0 }}>{row.order_no}</TableCell>
                      <TableCell sx={{ py: 0 }}>
                        {CheckAndReturn.transformToDate2(
                          row.order_date
                        ).toString() || "N/A"}
                      </TableCell>
                      <TableCell sx={{ py: 0 }}>₹{row.total_amount}</TableCell>
                      <TableCell sx={{ py: 0 }}>{row.order_status}</TableCell>
                      {
                        company_type === "product" && (
                          <TableCell sx={{ py: 0 }}>
                            {
                              Boolean(row.is_dispatch) === true ?
                                <span style={{ color: 'green' }}>dispatched</span>
                                :
                                <span style={{ color: 'red' }}>pending</span>
                            }
                          </TableCell>
                        )
                      }
                      {
                        Boolean(is_admin) === true && (
                          <TableCell sx={{ py: 0 }}>
                            <Checkbox
                              disabled={isApproved || Boolean(!is_admin)}
                              checked={
                                isApproved === false
                                  ? selectedRows.includes(row.id)
                                  : true
                              }
                              onChange={() => handleCheckboxChange(row.id)}
                            />
                          </TableCell>
                        )}
                      {/* <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell> */}
                      <TableCell sx={{ py: 0 }}>
                        <Box
                          sx={{
                            borderRadius: 10,
                            width: "fit-content",
                            transition: "100ms",
                            cursor: "pointer",
                            p: 0,
                            m: 0,
                          }}
                        >
                          <Stack direction={"row"}>
                            <Tooltip title="Print" placement="top">
                              <IconButton onClick={() => handlePrint(row.id)}>
                                <PrintTwoToneIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit" arrow>
                              <IconButton
                                disabled={
                                  (row.order_status === "processing" &&
                                    Boolean(is_admin) === false) ||
                                  row.order_status === "processed"
                                }
                                onClick={() => handleEditData(row)}
                              >
                                <EditIcon
                                  color={
                                    (row.order_status === "processing" &&
                                      Boolean(is_admin) === false) ||
                                      row.order_status === "processed"
                                      ? ""
                                      : "primary"
                                  }
                                />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                          <Stack direction={"row"}>
                            <Tooltip title="See Details" arrow>
                              <IconButton
                                onClick={() => handleVisibilityIcon(row)}
                              >
                                <VisibilityIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Create Invoice" arrow>
                              <IconButton
                                onClick={() => handleOrderToInvoice(row)}
                                disabled={!((row.order_status === "processing") && Boolean(is_admin))}
                              >
                                <ReceiptIcon />
                              </IconButton>
                            </Tooltip>
                            {
                              ((row.order_status === 'processed') && Boolean(is_admin) && (company_type === "product")) === true
                              &&
                              <Tooltip>
                                <IconButton color="warning" onClick={() => handleDispatch(row)} disabled={Boolean(row.is_dispatch)}>
                                  <LocalShippingIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </Stack>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )))
                  :
                  (
                    orderDataList.length > 0 ?
                      paginatedData.map((row, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          sx={{
                            ...rowStyle,
                            ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                          }}
                        >
                          <TableCell sx={{ py: 0 }}>{page * rowsPerPage + rowIndex + 1}</TableCell>
                          <TableCell sx={{ py: 0 }}>{row.partyname}</TableCell>
                          <TableCell sx={{ py: 0 }}>{row.order_no}</TableCell>
                          <TableCell sx={{ py: 0 }}>
                            {CheckAndReturn.transformToDate2(
                              row.order_date
                            ).toString() || "N/A"}
                          </TableCell>
                          <TableCell sx={{ py: 0 }}>₹{row.total_amount}</TableCell>
                          <TableCell sx={{ py: 0 }}>{row.order_status}</TableCell>
                          {
                            Boolean(is_admin) === true &&
                            <TableCell sx={{ py: 0 }}>
                              <Checkbox
                                disabled={isApproved || Boolean(!is_admin)}
                                checked={
                                  isApproved === false
                                    ? selectedRows.includes(row.id)
                                    : true
                                }
                                onChange={() => handleCheckboxChange(row.id)}
                              />
                            </TableCell>
                          }

                          <TableCell sx={{ py: 0 }}>
                            <Box
                              sx={{
                                borderRadius: 10,
                                width: "fit-content",
                                transition: "100ms",
                                cursor: "pointer",
                                padding: 0.5,
                              }}
                            >
                              <Stack direction={'row'}>
                                <Tooltip title="Print" placement="top">
                                  <IconButton onClick={() => handlePrint(row.id)}>
                                    <PrintTwoToneIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit" arrow>
                                  <IconButton disabled={(row.order_status === 'processing' && Boolean(is_admin) === false) || (row.order_status === 'processed')} onClick={() => handleEditData(row)}>
                                    <EditIcon color={(row.order_status === 'processing' && Boolean(is_admin) === false) || (row.order_status === 'processed') ? "" : "primary"} />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                              <Stack direction={'row'}>
                                <Tooltip title="See Details" arrow>
                                  <IconButton
                                    onClick={() => handleVisibilityIcon(row)}
                                  >
                                    <VisibilityIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Create Invoice" arrow>
                                  <IconButton onClick={() => handleOrderToInvoice(row)} disabled={!((row.order_status === 'processing') && Boolean(is_admin))}>
                                    <ReceiptIcon />
                                  </IconButton>
                                </Tooltip>
                                {
                                  ((row.order_status === 'processed') && Boolean(is_admin) && (company_type === "product")) === true
                                  &&
                                  <Tooltip>
                                    <IconButton color="warning" onClick={() => handleDispatch(row)} disabled={Boolean(row.is_dispatch)}>
                                      <LocalShippingIcon />
                                    </IconButton>
                                  </Tooltip>
                                }
                              </Stack>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                      :
                      <TableRow>
                        <TableCell colSpan={12}>
                          <Box textAlign={"center"}>
                            <Typography variant="body1" color={"red"}>
                              No data found.
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                  )
                }
                {emptyRows > 0 && (
                  <TableRow style={{ height: 1 * emptyRows }}>
                    <TableCell colSpan={headers.length + 2} />
                  </TableRow>
                )}
              </TableBody>
            </Table>

          </TableContainer>
          {
            pagination && (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )
          }
        </>
      )}

      <BootstrapDialog
        onClose={handleClose1}
        aria-labelledby="customized-dialog-title"
        open={openGstDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Sales Order
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose1}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <form>
            <div>
              <TextField
                size="small"
                required
                type="date"
                label="Date"
                value={selectedDate}
                InputLabelProps={{ shrink: true }}
                onChange={handleDate}
              />
            </div>
            <div style={{ marginTop: "16px" }}>
              <TextField
                id="outlined-basic"
                label="Remarks"
                variant="outlined"
                defaultValue=""
                inputRef={remarksRef}
              />
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSaveSelected}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default memo(SalesOrderTable);
