import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import { NavLink } from "react-router-dom";
import { Typography } from "@mui/material";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import SummarizeIcon from "@mui/icons-material/Summarize";

//Icon Imports
import ReportIcon from "@mui/icons-material/Report";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Zoom from "@mui/material/Zoom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SavingsIcon from "@mui/icons-material/Savings";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useMediaQuery } from "@mui/material";
import { GrDocumentUpdate } from "react-icons/gr";
//nested icon Imports
import CategoryIcon from "@mui/icons-material/Category";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import SellIcon from "@mui/icons-material/Sell";
import Person4Icon from "@mui/icons-material/Person4";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import ScaleIcon from "@mui/icons-material/Scale";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SiteLogo from "../Assets/images/siteLogoWhite.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  closeExpand,
  openExpand,
  trigger,
} from "../../redux/reducers/SideExpand";
import MenuListCard from "../MenuListCard/MenuListCard";
import { motion, AnimatePresence } from "framer-motion";
import { Tooltip } from "@mui/material";

import MasterEntryIcon from "../Assets/images/MasterEntryIcon.webp";
import BrandIcon from "../Assets/images/BrandIcon.png";
//import CategoryIcon from '../Assets/images/CategoryIcon.jpg';
import HSNIcon from "../Assets/images/HSNIcon.png";
import ItemIcon from "../Assets/images/ItemIcon.jpeg";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SuperCategoryIcon from "../Assets/images/SuperCategoryIcon.jpg";
import AccessMasterIcon from "../Assets/images/AccessMasterIcon.png";
import AccountMasterIcon from "../Assets/images/AccountMasterIcon.png";
import InvoiceIcon from "../Assets/images/InvoiceIcon.png";
import SupplierIcon from "../Assets/images/SupplierIcon.webp";
import CatalogIcon from "../Assets/images/CatalogIcon.jpg";
import SaleOrderIcon from "../Assets/images/SaleOrderIcon.png";
import SalesReport from "../Assets/images/SalesReport.png";
import salesReportImg from "../Assets/images/SalesReport.jpg"

import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AccountMasterIcon2 from "../Assets/images/accountMasterEntry.png";

import stockUpdate from "../Assets/images/stockUpdate.png";

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const NavToolTip = ({ text, children }) => {
  return (
    <Tooltip
      TransitionComponent={Zoom}
      leaveDelay={200}
      title={text}
      placement="right"
      arrow
      sx={{ fontSize: "16px" }}
    >
      {children}
    </Tooltip>
  );
};

export default function Sidebar() {
  //#region code
  const dispatch = useDispatch();
  const open = useSelector((state) => state.sideExpand.open);
  const isMobile = useMediaQuery("(max-width:600px)");

  const { is_super, is_admin } = useSelector((state) => state.GlobalVariables);
  const { company_type } = useSelector((state) => state.admin);

  const [isMenuExpanded, setIsMenuExpanded] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(0);

  React.useEffect(() => {
    if (isMobile) {
      dispatch(closeExpand());
    } else {
      dispatch(openExpand());
    }
  }, [isMobile]);

  const handleDrawerOpen = () => {
    dispatch(trigger());
  };

  const handleDrawerClose = () => {
    // setOpen(false);
    dispatch(trigger());
  };

  const handleExpand = (number) => {
    setSelectedMenu(number);
    setIsMenuExpanded(!isMenuExpanded);
  };
  //#endregion

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: "#333",
          },
        }}
      >
        <DrawerHeader>
          <img
            src={SiteLogo}
            alt="logo"
            width="150"
            hidden={open ? false : true}
          />
          <IconButton onClick={handleDrawerClose}>
            {!open ? (
              <ArrowForwardIosIcon sx={{ color: "white" }} />
            ) : (
              <ArrowBackIosNewIcon sx={{ color: "white" }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <NavLink
          to="/dashboard"
          style={{ textDecoration: "none", color: "#FFF4F4" }}
        >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <NavToolTip text="Dashboard">
                  <DashboardIcon sx={{ color: "white" }} />
                </NavToolTip>
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <Divider
          variant="middle"
          sx={{
            backgroundColor: "#6270e488",
            marginBottom: 2,
          }}
        />

        <MenuListCard
          number={0}
          text={"Inventory Master"}
          icon={
            <NavToolTip text="Inventory Master">
              <img
                src={MasterEntryIcon}
                style={{
                  width: "25px",
                  filter: "invert(100%)",
                }}
                alt="Inventory Master"
              />
            </NavToolTip>
          }
          secondaryIcon={
            isMenuExpanded && selectedMenu === 0 ? (
              <ArrowDropUpIcon sx={{ color: "white" }} />
            ) : (
              <ArrowDropDownIcon sx={{ color: "white" }} />
            )
          }
          isExpandable={true}
          handleExpand={handleExpand}
        />
        <AnimatePresence>
          {isMenuExpanded && selectedMenu === 0 && (
            <Box
              component={motion.div}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "100%", opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
            >
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />

              <MenuListCard
                url="/master-entry/brand"
                nested={true}
                fSize="0.9rem"
                text={"Brand"}
                icon={
                  <NavToolTip text="Brand">
                    <img
                      src={BrandIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Brand"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              {/* <MenuListCard url='/master-entry/batch' nested={true} fSize='0.9rem' text={'Batch'} icon={<NavToolTip text='Batch'><BatchPredictionIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} /> */}

              <MenuListCard
                url="/master-entry/category"
                nested={true}
                fSize="0.9rem"
                text={"Category"}
                icon={
                  <NavToolTip text="Category">
                    <CategoryIcon sx={{ color: "white" }} />
                    {/* <img
                                src={CategoryIcon}
                                style={{
                                    width: '25px',
                                    filter: 'invert(100%)', // Apply the invert filter
                                }}
                                alt="Category"
                            /> */}
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              <MenuListCard
                url="/master-entry/group"
                nested={true}
                fSize="0.9rem"
                text={"Group"}
                icon={
                  <NavToolTip text="Group">
                    <SellIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              <MenuListCard
                url="/master-entry/units"
                nested={true}
                fSize="0.9rem"
                text={"Unit"}
                icon={
                  <NavToolTip text="Unit">
                    <ScaleIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              <MenuListCard
                url="/master-entry/item"
                nested={true}
                fSize="0.9rem"
                text={"Items"}
                icon={
                  <NavToolTip text="Items">
                    <ViewInArIcon sx={{ color: "white" }} />
                    {/* <img
                                src={ItemIcon}
                                style={{
                                    width: '25px',
                                    filter: 'invert(100%)', // Apply the invert filter
                                }}
                                alt="Item"
                            /> */}
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              <MenuListCard
                url="/master-entry/hsn"
                nested={true}
                fSize="0.9rem"
                text={"HSN"}
                icon={
                  <NavToolTip text="HSN">
                    <img
                      src={HSNIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="HSN"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              {/* <MenuListCard url='/updateProduct' nested={true} fSize='0.9rem' number={2} text={'Update Product'} icon={<NavToolTip text='UpdateProduct'><Inventory2Icon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}

              <MenuListCard
                url="/master-entry/super-category"
                nested={true}
                fSize="0.9rem"
                number={2}
                text={"Super Category"}
                icon={
                  <NavToolTip text="Super Category">
                    <img
                      src={SuperCategoryIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Super Category"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
                isExpandable={false}
              />

              <MenuListCard
                url="/master-entry/location"
                nested={true}
                fSize="0.9rem"
                number={14}
                text={"Location"}
                icon={
                  <NavToolTip text="Location">
                    <LocationOnIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
                isExpandable={false}
              />

              <MenuListCard
                url="/master-entry/catalog"
                nested={true}
                fSize="0.9rem"
                number={14}
                text={"Catalog"}
                icon={
                  <NavToolTip text="Catalog">
                    <img
                      src={CatalogIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Catalog"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
                isExpandable={false}
              />

              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
            </Box>
          )}
        </AnimatePresence>

        <MenuListCard
          url="/invoice"
          // fSize="0.9rem"
          number={12}
          text={"Invoice"}
          icon={
            <NavToolTip text="Invoice">
              <img
                src={InvoiceIcon}
                style={{
                  width: "25px",
                  filter: "invert(100%)",
                }}
                alt="Invoice"
              />
            </NavToolTip>
          }
          secondaryIcon={<ArrowDropDownIcon />}
          isExpandable={false}
        />

        {/* <MenuListCard
          url="/subscriptions"
          number={13}
          // fSize="0.9rem"
          text={"Subscriptions"}
          icon={
            <NavToolTip text="Subscriptions">
              <AssessmentIcon sx={{ color: "white" }} />
            </NavToolTip>
          }
          secondaryIcon={<ArrowDropDownIcon />}
          isExpandable={false}
        /> */}

        {/* <MenuListCard
          url="/subscriptions-order"
          number={13}
          // fSize="0.9rem"
          text={"Subscriptions Order"}
          icon={
            <NavToolTip text="Subscriptions Order">
              <SubscriptionsIcon sx={{ color: "white" }} />
            </NavToolTip>
          }
          secondaryIcon={<ArrowDropDownIcon />}
          isExpandable={false}
        />
        <MenuListCard
          url="/subscriptions-dispatch"
          number={13}
          // fSize="0.9rem"
          text={"Subscriptions Dispatch"}
          icon={
            <NavToolTip text="Subscriptions Dispatch">
              <LoyaltyIcon sx={{ color: "white" }} />
            </NavToolTip>
          }
          secondaryIcon={<ArrowDropDownIcon />}
          isExpandable={false}
        /> */}
        <MenuListCard
          url="/sales-order"
          number={14}
          // fSize="0.9rem"
          text={"Sales Order"}
          icon={
            <NavToolTip text="Sales Order">
              <img
                src={SaleOrderIcon}
                style={{
                  width: "25px",
                  filter: "invert(100%)",
                }}
                alt="Sales Order"
              />
            </NavToolTip>
          }
          secondaryIcon={<ArrowDropDownIcon />}
          isExpandable={false}
        />

        {/* Account Master stated */}

        <MenuListCard
          number={10}
          text={
            <Typography sx={{ fontSize: "14px" }}>
              {" "}
              {/* Adjust the size as needed */}
              Account Master
            </Typography>
          }
          icon={
            <NavToolTip text="Account Master">
              <img
                src={AccountMasterIcon2}
                style={{
                  width: "25px",
                  filter: "invert(100%)",
                }}
                alt="Account Master"
              />
            </NavToolTip>
          }
          secondaryIcon={
            isMenuExpanded && selectedMenu === 10 ? (
              <ArrowDropUpIcon sx={{ color: "white" }} />
            ) : (
              <ArrowDropDownIcon sx={{ color: "white" }} />
            )
          }
          isExpandable={true}
          handleExpand={handleExpand}
        />
        <AnimatePresence>
          {isMenuExpanded && selectedMenu === 10 && (
            <Box
              component={motion.div}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "100%", opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
            >
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
              <MenuListCard
                url="/account-master-entry/customers"
                nested={true}
                fSize="0.9rem"
                text={"Customers"}
                icon={
                  <NavToolTip text="Customers">
                    <PeopleAltIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <MenuListCard
                url="/account-master-entry/account-master"
                nested={true}
                fSize="0.9rem"
                text={"Account Master"}
                icon={
                  <NavToolTip text="Account Master">
                    <img
                      src={AccountMasterIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Account Master"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
                isExpandable={false}
              />

              <MenuListCard
                url="/account-master-entry/supplier"
                nested={true}
                fSize="0.9rem"
                text={"Suppliers"}
                icon={
                  <NavToolTip text="Suppliers">
                    <img
                      src={SupplierIcon}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Supplier"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
                isExpandable={false}
              />

              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
            </Box>
          )}
        </AnimatePresence>

        {/* account master entery ened */}

        {/* <MenuListCard url='/customers' number={1} text={'Customers'} icon={<NavToolTip text='Customers'>
                    <PeopleAltIcon sx={{ color: 'white' }} />
                </NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}

        {/* <MenuListCard url='/accessMaster' number={1} text={'Access Master'} icon={<NavToolTip text='Access Master'>
                    <img
                        src={AccessMasterIcon}
                        style={{
                            width: '25px',
                            filter: 'invert(100%)',
                        }}
                        alt="Access Master"
                    />
                </NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}

        {/* <MenuListCard url='/invoice' number={3} text={'Invoice'} icon={<NavToolTip text='Invoice'>
                    <img
                        src={InvoiceIcon}
                        style={{
                            width: '25px',
                            filter: 'invert(100%)',
                        }}
                        alt="Invoice"
                    />
                </NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}

        {/* <MenuListCard
          url="/supplier"
          number={2}
          text={"Suppliers"}
          icon={
            <NavToolTip text="Suppliers">
              <img
                src={SupplierIcon}
                style={{
                  width: "25px",
                  filter: "invert(100%)",
                }}
                alt="Supplier"
              />
            </NavToolTip>
          }
          secondaryIcon={<ArrowDropDownIcon />}
          isExpandable={false}
        /> */}

        <MenuListCard
          number={5}
          text={"Transaction"}
          icon={
            <NavToolTip text="Transaction">
              <AccountBalanceIcon sx={{ color: "white" }} />
            </NavToolTip>
          }
          secondaryIcon={
            isMenuExpanded && selectedMenu === 5 ? (
              <ArrowDropUpIcon sx={{ color: "white" }} />
            ) : (
              <ArrowDropDownIcon sx={{ color: "white" }} />
            )
          }
          isExpandable={true}
          handleExpand={handleExpand}
        />

        <AnimatePresence>
          {isMenuExpanded && selectedMenu === 5 && (
            <Box
              component={motion.div}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "100%", opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
            >
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
              <MenuListCard
                url="/transaction/create-vouchers"
                nested={true}
                fSize="0.9rem"
                text={"Cash Voucher"}
                icon={
                  <NavToolTip text="Cash Voucher">
                    <MonetizationOnIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <MenuListCard
                url="/transaction/journal-entry"
                nested={true}
                fSize="0.9rem"
                text={"Journal Voucher"}
                icon={
                  <NavToolTip text="Journal Voucher">
                    <PriceChangeIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <MenuListCard
                url="/transaction/Gst-transaction-report"
                nested={true}
                fSize="0.9rem"
                text={"Gst Transaction"}
                icon={
                  <NavToolTip text="Gst Transaction">
                    <ReceiptLongIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <MenuListCard
                url="/transaction/stockUpdate"
                nested={true}
                fSize="0.9rem"
                text={"Stock Update"}
                icon={
                  <NavToolTip text="Stock Update">
                    <img
                      src={stockUpdate}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Super Category"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              <MenuListCard
                url="/transaction/purchase-order"
                nested={true}
                fSize="0.9rem"
                text={"Purchase Order"}
                icon={
                  <NavToolTip text="Purchase Order">
                    <Person4Icon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <MenuListCard
                url="/transaction/purchase-bill"
                nested={true}
                fSize="0.9rem"
                text={"Purchase Bill"}
                icon={
                  <NavToolTip text="Purchase Bill">
                    <ViewInArIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />

              <MenuListCard
                url="/transaction/dispatch"
                nested={true}
                fSize="0.9rem"
                text={"Dispatch"}
                icon={
                  <NavToolTip text="Dispatch">
                    <LocalShippingIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
                isExpandable={false}
              />
              {/* <MenuListCard url='/transaction/create-receipts' nested={true} fSize='0.9rem' text={'Create Reciepts'} icon={<NavToolTip text='Create Reciepts'><CategoryIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/transaction/credit-note' nested={true} fSize='0.9rem' text={'Credit Note'} icon={<NavToolTip text='Credit Note'><ScaleIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} />
                        <MenuListCard url='/transaction/debit-note' nested={true} fSize='0.9rem' text={'Debit Note'} icon={<NavToolTip text='Debit Note'><CheckBoxOutlineBlankIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} /> */}
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
            </Box>
          )}
        </AnimatePresence>

        <MenuListCard
          number={6}
          text={"Reports"}
          icon={
            <NavToolTip text="Reports">
              <ReportIcon sx={{ color: "white" }} />
            </NavToolTip>
          }
          secondaryIcon={
            isMenuExpanded && selectedMenu === 6 ? (
              <ArrowDropUpIcon sx={{ color: "white" }} />
            ) : (
              <ArrowDropDownIcon sx={{ color: "white" }} />
            )
          }
          isExpandable={true}
          handleExpand={handleExpand}
        />

        <AnimatePresence>
          {isMenuExpanded && selectedMenu === 6 && (
            <Box
              component={motion.div}
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: "100%", opacity: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ height: 0, opacity: 0, transition: { duration: 0.3 } }}
            >
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
              <MenuListCard
                url="/report/outstanding-report"
                nested={true}
                fSize="0.9rem"
                text={"Outstanding Report"}
                icon={
                  <NavToolTip text="Outstanding Report">
                    <SellIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <MenuListCard
                url="/report/gst-invoice-report"
                nested={true}
                fSize="0.9rem"
                text={"Gst Invoice Report"}
                icon={
                  <NavToolTip text="Gst Invoice Report">
                    <PriceChangeIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
              <MenuListCard
                url="/report/sales-report"
                nested={true}
                fSize="0.9rem"
                text={"Sales Order Report"}
                icon={
                  <NavToolTip text="Sales Order Report">
                    <img
                      src={SalesReport}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Supplier"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
              <MenuListCard
                url="/report/sales-summary-report"
                nested={true}
                fSize="0.9rem"
                text={"Sales Order Summary"}
                icon={
                  <NavToolTip text="Sales Order Summary">
                    <SummarizeIcon sx={{ color: "white" }} />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              {/* <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} /> */}
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
              <MenuListCard
                url="/report/sales-summary"
                nested={true}
                fSize="0.9rem"
                text={"Sales  Summary"}
                icon={
                  <NavToolTip text="Sales Summary">
                   <img
                      src={salesReportImg}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt="Supplier"
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
              <MenuListCard
                url="/report/all-ledgers"
                nested={true}
                fSize="0.9rem"
                text={"Ledger Report"}
                icon={
                  <NavToolTip text="Ledger Report">
                   <img
                      src={salesReportImg}
                      style={{
                        width: "25px",
                        filter: "invert(100%)",
                      }}
                      alt=""
                    />
                  </NavToolTip>
                }
                secondaryIcon={<ArrowDropDownIcon />}
              />
              <Divider sx={{ backgroundColor: "black", marginLeft: 5 }} />
            </Box>
          )}
        </AnimatePresence>

        {Boolean(is_admin) === true && (
          <MenuListCard
            url="/register-company"
            number={10}
            text={"Company"}
            icon={
              <NavToolTip text="Company">
                <AppRegistrationIcon sx={{ color: "white" }} />
              </NavToolTip>
            }
            secondaryIcon={<ArrowDropDownIcon />}
            isExpandable={false}
          />
        )}
        {is_admin && (
          <MenuListCard
            url="/configuration"
            number={11}
            text={"Configuration"}
            icon={
              <NavToolTip text="Configuration">
                <SettingsIcon sx={{ color: "white" }} />
              </NavToolTip>
            }
            secondaryIcon={<ArrowDropDownIcon />}
            isExpandable={false}
          />
        )}
        {is_admin && (
        <MenuListCard
          number={12}
          url="/profile"
          text={"Profile"}
          icon={
            <NavToolTip text="Profile">
              <AccountBoxIcon sx={{ color: "white" }} />
            </NavToolTip>
          }
          secondaryIcon={<ArrowDropDownIcon />}
          isExpandable={false}
        />
      )}
        {is_admin && (
          <MenuListCard
            number={12}
            url="/access-master"
            text={"Access Master"}
            icon={
              <NavToolTip text="Access Master">
                <AccountBoxIcon sx={{ color: "white" }} />
              </NavToolTip>
            }
            secondaryIcon={<ArrowDropDownIcon />}
            isExpandable={false}
          />
        )}
        {/* <MenuListCard number={13} url='/change-password' text={'Change Password'} icon={<NavToolTip text='Change Password'><PasswordIcon sx={{ color: 'white' }} /></NavToolTip>} secondaryIcon={<ArrowDropDownIcon />} isExpandable={false} /> */}
        <Divider />
      </Drawer>
    </Box>
  );
}
