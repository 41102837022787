import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import { assignType } from "../../redux/reducers/Admin";
import Cookies from 'universal-cookie';
import { decodeToken } from "react-jwt";
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import jwtDecode from "jwt-decode";
import LoginIcon from "@mui/icons-material/Login";
import bk from "../../Components/Assets/images/wbk.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";

import {
  Box,
  Typography,
  Container,
  TextField,
  FormControlLabel,
  Grid,
  Checkbox,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import configServ from "../../services/config";
import {
  rSetCompanyAddress,
  rSetCompanyCountry,
  rSetCompanyId,
  rSetCompanyName,
  rSetCompanyState,
  rSetCurrency,
  rSetEncodedData,
  rSetIsAdmin,
  rSetIsSuper,
  rSetLogin,
  rSetToken,
  rSetUserId,
} from "../../redux/reducers/GlobalVariables";
import HomeIcon from "@mui/icons-material/Home";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { decrypt, encrypt } from "../../redux/encryption";

//FUNCTION
const AdminLogin = () => {
  //#region code
  //const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const [formData, setFormData] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const {isUserAdmin} = useSelector((state) => state.user)
  const { company_type } = useSelector((state) => state.admin);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);
  const [isOrganisationUser, setIsOrganisationUser] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsOrganisationUser(event.target.checked);
  };
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setLoginError(true);
  };

  const handleCloseDialog = () => {
    setLoginError(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookie = new Cookies();

  // Access the state passed from the previous component

  const logout = () => {
    //persistor.purge(); // This will remove all data persisted by redux-persist
    localStorage.removeItem("persist:dcrm");
    localStorage.clear();
  };

  useEffect(() => {
    const data = cookie.get("dcrm_admin_login");
    if (data) {
      const dcrm_admin_login = decrypt(data);
      if (dcrm_admin_login) {
        setFormData((prev) => ({
          email: dcrm_admin_login.email,
          password: dcrm_admin_login.password
        }));
        setIsOrganisationUser(dcrm_admin_login.isOrganisationUser);
        setRememberMe(true);
      }
    }

    if (localStorage.getItem("session") === "expired") {
      logout();
      handleOpenDialog(
        "Session Expired",
        "Session expired. Please login to continue",
        "error"
      );
    }
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // to fetch data from text box
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update the form data
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));

    // Reset the validation error when the user starts typing
    if (validationErrors[name]) {
      setValidationErrors((prevValidationError) => ({
        ...prevValidationError,
        [name]: "",
      }));
    }
  };

  const validate = async () => {
    const errors = {};
    if (!formData.email) {
      errors.email = "required";
    }
    if (!formData.password) {
      errors.password = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  // to handle login
  const handleLogin = async (e) => {
    e.preventDefault();

    const isValid = await validate();
    if (isValid === false) {
      return false;
    }

    const dataToSend = {
      email: formData.email,
      password: formData.password,
      isOrganisationUser: isOrganisationUser
    }

    try {
      dispatch(triggerLoader());
      const res =
        isOrganisationUser === false
          ? await configServ.admin_login(dataToSend)
          : await configServ.user_admin_login(dataToSend);

      if (res.status === 200) {
        const result = res.data;
        dispatch(rSetLogin(true));

        localStorage.setItem("dcrm_token", result.token);
        const oneHour = 6.5 * 60 * 60 * 1000; // 1 hour in milliseconds
        const expirationDate = new Date(Date.now() + oneHour);
        cookie.set("dcrm_token", result.token, {
          expires: expirationDate,
        });
        // cookie.set("userType", "admin", {
        //   expires: expirationDate,
        // });

        if (rememberMe) {
          const dcrm_admin_login = encrypt(dataToSend);
          const oneWeek = 6 * 29.5 * 60 * 60 * 1000; // 1 week in milliseconds
          const expirationDateLogin = new Date(Date.now() + oneWeek);
          cookie.set("dcrm_admin_login", dcrm_admin_login, {
            expires: expirationDateLogin,
          });
        }
        else {
          cookie.remove("dcrm_admin_login")
        }

        dispatch(rSetUserId(result.userId));
        dispatch(rSetCompanyId(result.company_id));
        dispatch(rSetCompanyName(result.company_name));
        dispatch(rSetCompanyAddress(result.company_address));
        dispatch(rSetCompanyCountry(result.country));
        dispatch(rSetCompanyState(result.state));
        dispatch(assignType(result.company_type));
        dispatch(rSetCurrency(result.currency));
        dispatch(rSetIsSuper(result.is_super));
        dispatch(rSetIsAdmin(result.is_admin));
        dispatch(rSetEncodedData(result.encoded_data));
        dispatch(assignType(result.company_type));
        navigate("/Dashboard", { replace: true });
      } else {
        handleOpenDialog("Error", "Invalid Login credentials", "error");
      }
    } catch (e) {
      console.log(e);
      handleOpenDialog("Error", "Invalid Login credentials", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };
  //#endregion

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          width: "100vw",
          height: "100vh",
          backgroundImage: `url(${bk})`,
          //background: lightMode ? '#ffffff' : '#090e34',//'linear-gradient(to bottom, #F8F6F4, #D2E9E9)',
          opacity: 1,
          zIndex: -1,
        }}
      >
        <AlertDialog
          open={loginError}
          setOpen={setLoginError}
          title={title}
          message={dialogContent}
          buttonTitle="Ok"
          buttonType={buttonType}
        />

        <Container
          component="main"
          maxWidth="sm"
          sx={{ position: "relative", zIndex: 1 }}
        >
          {/* Add your Grid here at the top */}
          <Grid
            item
            xs={12}
            md={10}
            my={1}
            sx={{
              position: "absolute",
              top: -10, // Adjust this value to move it above the container
              left: 20, // Adjust the left value as needed
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "20px",
                  display: "flex",
                  alignItems: "center",
                  //backgroundColor: "green",
                  "&:hover": {
                    backgroundColor: "darkgreen",
                  },
                }}
              >
                <ReplyAllIcon sx={{ marginRight: 1 }} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 1,
                  }}
                >
                  <HomeIcon />
                </Box>
              </Button>
            </Link>
          </Grid>

          <Box
            sx={{
              boxShadow: 3,
              borderRadius: 2,
              px: 4,
              py: 8,
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "rgb(218, 243, 255)",
            }}
          >
            <Typography variant="h5">
              <b>Welcome to Dovetail-crm!</b>
            </Typography>
            <Typography variant="caption">
              Please sign in to continue as admin
            </Typography>
            <form onSubmit={handleLogin}>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <Grid item xs={12} md={10} mb={1} mt={3}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={formData.email}
                    onChange={handleChange}
                    size={"small"}
                    sx={{ backgroundColor: "white" }}
                    error={!!validationErrors.email}
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"} // Toggle between 'text' and 'password'
                    id="password"
                    value={formData.password}
                    onChange={handleChange}
                    size={"small"}
                    sx={{ backgroundColor: "white" }}
                    error={!!validationErrors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}{" "}
                            {/* Toggle icons */}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  container
                  direction={{ xs: "row", md: "row" }}
                  spacing={2}
                  sx={{
                    justifyContent: { xs: "flex-start", md: "center" }, // Center on large screens, start on small screens
                  }}
                >

                  {/* Remember Me Checkbox */}
                  <Grid item xs={6} md={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={handleRememberMeChange}
                          size="small" // Makes the checkbox smaller
                        />
                      }
                      label="Remember Me"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 600,
                        color: "rgba(0, 0, 0, 0.87)",
                        marginLeft: 0,
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                      }}
                    />
                  </Grid>

                  {/* Organisation user */}
                  <Grid item xs={6} md={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isOrganisationUser}
                          onChange={handleCheckboxChange}
                          size="small" // Makes the checkbox smaller
                        />
                      }
                      label="Organisation User"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 600,
                        color: "rgba(0, 0, 0, 0.87)",
                        marginLeft: 0,
                        display: "flex",
                        alignItems: "center",
                        flex: 1,
                      }} // Adjusts font size and bold
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={10}>
                  <Button
                    type="submit"
                    fullWidth
                    size="small"
                    variant="contained"
                    startIcon={<LoginIcon sx={{ margin: "5px 10px" }} />}
                    sx={{ borderRadius: "20px" }}
                  >
                    Login
                  </Button>
                </Grid>

                <Grid container justifyContent="flex-end" mt={2}>
                  <Grid item>
                    <Link
                      to="/forgetPassword"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="body2" color="primary">
                        Forgot Password?
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default AdminLogin;
