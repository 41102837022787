import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Paper,
  Divider,
  Typography,
  Button,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Pagination,
  TextField,
  InputAdornment,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import ProductCard from "./ProductCard";
import { useCallback } from "react"; // Add useCallback for performance optimization
import ClearIcon from "@mui/icons-material/Clear"; // Import ClearIcon
//icons
import SearchIcon from "@mui/icons-material/Search";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import configServ from "../../../services/config";
import LoaderNew from "../../../Components/Loader/LoaderNew";

const Product = () => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const [item, setItem] = useState([]);
  const [filteredItem, setFilteredItem] = useState(item);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    filter_by_brand: "all",
    filter_by_category: "all",
  });
  const [search, setSearch] = useState("");
  const [view, setView] = useState("grid");
  const [filterItems, setFilterItems] = useState({
    company_id: company_id,
    brand_id: "",
    category_id: "",
    search_item: "",
  });
  const [totalItems, setTotalItems] = useState(0);
  const [searchResult, setSearchResult] = useState(true);
  const [loader, setLoader] = useState(true);

  const [typingTimeout, setTypingTimeout] = useState(null);
  const itemsPerPage = 15;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedItems = item.slice(startIndex, startIndex + itemsPerPage);
  const handleReset = () => {
    setSearch("");
    setFilterItems({
      ...filterItems,
      brand_id: "",
      category_id: "",
      search_item: "",
    });
    setCurrentPage(1);
    fetchItem(); // Refetch items after reset
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFilterItems((state) => ({
      ...state,
      [name]: value,
    }));
    // Trigger search each time a filter is changed
    fetchItem();
  };

  const fetchItem = async () => {
    const dataToSend = {
      ...filterItems,
      perPage: itemsPerPage,
      page: currentPage,
    };
    setLoader(true);
    try {
      const result = await configServ.getItemDetailsByCompIdPage(dataToSend);
      if (result.success) {
        setItem(result.paginationData);
        setTotalItems(result.totalRecords);
        setSearchResult(true);
      } else {
        setSearchResult(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoader(false);
    }
  };

  const fetchCategory = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: null,
        search: null,
        per_page: null,
        page: null,
      };
      const res = await configServ.getcategory(dataToSend);
      console.log("fetchCategory=", res);

      if (res.status === 200) {
        setCategory(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchBrand = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: null,
        search: null,
        per_page: null,
        page: null,
      };
      const res = await configServ.getBrand(dataToSend);
      console.log("fetchBrand=", res);
      if (res.status === 200) {
        setBrand(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategory();
    fetchBrand();
  }, []);

  useEffect(() => {
    fetchItem();
  }, [filterItems, itemsPerPage, currentPage]);

  const handleSearch = useCallback(
    (searchText) => {
      // Clear any existing timeout
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      // Set a new timeout for debounce (e.g., 500ms)
      const timeout = setTimeout(() => {
        setFilterItems((state) => ({
          ...state,
          search_item: searchText,
        }));
      }, 500);

      setTypingTimeout(timeout);
    },
    [typingTimeout]
  );

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearch(value); // Update the search state
    handleSearch(value); // Trigger debounced search
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  //#endregion

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
        px={3}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          py={1}
        >
          <Grid item xs={6} justifyContent={"flex-start"}>
            <Typography variant="body" color="text.secondary" fontWeight={700}>
              FILTER:
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent={"flex-end"}>
            <RadioGroup
              aria-label="icons"
              name="icons"
              row
              value={view}
              onChange={(e) => setView(e.target.value)} // Update the view state
            >
              <Tooltip
                title={view === "grid" ? "List View" : "Grid View"}
                arrow
              >
                <FormControlLabel
                  value={view === "grid" ? "list" : "grid"} // Toggle the view on click
                  control={
                    <Radio
                      icon={
                        view === "grid" ? (
                          <FormatListBulletedIcon />
                        ) : (
                          <GridViewIcon />
                        )
                      }
                      checkedIcon={
                        view === "grid" ? (
                          <FormatListBulletedIcon />
                        ) : (
                          <GridViewIcon />
                        )
                      }
                      color="primary"
                    />
                  }
                />
              </Tooltip>
            </RadioGroup>
          </Grid>

          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Category</InputLabel>
              <Select
                fullWidth
                size="small"
                label="Category"
                name="category_id"
                defaultValue=""
                value={filterItems.category_id}
                onChange={handleFilter}
                sx={{ background: "white" }}
              >
                <MenuItem value="">All</MenuItem>
                {category.map((item) => {
                  return (
                    <MenuItem key={item.categoryid} value={item.categoryid}>
                      {item.categoryname}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Brand</InputLabel>
              <Select
                fullWidth
                size="small"
                label="Brand"
                name="brand_id"
                defaultValue=""
                value={filterItems.brand_id}
                onChange={handleFilter}
                sx={{ background: "white" }}
              >
                <MenuItem value="">All</MenuItem>
                {brand.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.brand_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                label="Search"
                size="small"
                sx={{ background: "white" }}
                name="search_item"
                value={search}
                onChange={handleInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSearch(search)}>
                        <SearchIcon />
                      </IconButton>
                      <IconButton onClick={handleReset}>
                        {" "}
                        {/* Reset button */}
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Divider
          sx={{
            backgroundColor: "#33333377",
            width: "100%",
            py: "1px",
            my: "5px",
          }}
        />

        <Grid container spacing={2} minHeight="50vh">
          {!loader &&
            searchResult &&
            item.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  lg={view === "list" ? 12 : 4}
                  md={view === "list" ? 12 : 6}
                  sm={view === "list" ? 12 : 6}
                  xs={12}
                >
                  <ProductCard data={item} view={view} />
                </Grid>
              );
            })}
          {!searchResult && (
            <Stack
              width="100%"
              justifyContent="center"
              alignItems="center"
              my={5}
            >
              <Typography
                variant="h5"
                sx={{
                  fontStretch: "expanded",
                }}
              >
                No items with specified filters
              </Typography>
            </Stack>
          )}
          {loader && (
            <Grid
              container
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "200px", // Optional: set a minimum height for better centering
              }}
            >
              <LoaderNew />
            </Grid>
          )}
        </Grid>
        <Pagination
          // count={Math.ceil(item.length / itemsPerPage)}
          count={Math.ceil(totalItems / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Grid>
    </>
  );
};
export default Product;
