import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Typography, Box, Stack } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import MiniLoader from "../MiniLoader/MiniLoader";

import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import PaymentIcon from "@mui/icons-material/Payment";

// import NestedTable from '../../../pages/Invoice/NestedTable';
// import CustNestedTable from './CustNestedTable';
import { motion, AnimatePresence } from "framer-motion";
import LoaderNew from "../../../Components/Loader/LoaderNew";

// function createData(name, code, population, size) {
//   const density = population / size;
//   return { name, code, population, size, density };
// }

export default function CustPaymentTable({ tableData, miniLoader }) {
  const [page, setPage] = React.useState(0);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions[1]);
  const [expand, setExpand] = React.useState(false);
  const [expandId, setExpandId] = React.useState(0);

  const handleExpand = (id) => {
    setExpand(!expand);
    setExpandId(id);
  };

  useEffect(() => {
    console.log(tableData);
  }, []);

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 170 },
    {
      id: "invoice_number ",
      label: "Invoice Number",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "date",
      label: `Date`,
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "transaction_type",
      label: "Transaction Type",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "bank",
      label: "Bank Name",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
    {
      id: "amount",
      label: "Amount",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: "100%" }}>
        <TableContainer
          sx={{ maxHeight: 440, display: { xs: "none", md: "block" } }}
          component={motion.div}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 440 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      backgroundColor: "#4CB5F5",
                      color: "white",
                    }}
                  >
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {miniLoader ? (
                // Display loader if data is still being fetched
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <LoaderNew />
                  </TableCell>
                </TableRow>
              ) : tableData.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={columns.length}>
                    <Typography variant="h6">No Records to show</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                tableData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <React.Fragment key={row.id}>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? "#f5f5f5" : "white",
                        }}
                      >
                        <TableCell
                          sx={{
                            minWidth: 100,
                            display: { xs: "none", md: "table-cell" },
                          }}
                        >
                          <AddIcon
                            onClick={() => handleExpand(row.id)}
                            color="primary"
                            sx={{
                              mr: "20px",
                              transition: "100ms",
                              cursor: "pointer",
                              ":hover": { transform: "scale(1.15)" },
                            }}
                          />
                          {++index}
                        </TableCell>
                        <TableCell>{row.invoice_number || "Payment without invoice"}</TableCell>
                        <TableCell>
                          {row.date
                            ? new Date(row.date).toISOString().split("T")[0]
                            : "N/A"}
                        </TableCell>
                        <TableCell>{row.payment_mode}</TableCell>
                        <TableCell>{row.account_name}</TableCell>
                        <TableCell>{row.amount}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* mobile view */}
        <Box
          sx={{
            display: { xs: "block", md: "none" },
            padding: 2,
            border: "1px solid #ddd",
            borderRadius: "8px",
            marginTop: 2,
            boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.5)",
          }}
        >
          {miniLoader ? (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: 200 }}
            >
              <LoaderNew />{" "}
              {/* Show loader on mobile view when miniLoader is true */}
            </Stack>
          ) : tableData.length > 0 ? (
            tableData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Box
                  key={row.id}
                  sx={{
                    padding: 2,
                    borderBottom: "1px solid #ddd",
                    backgroundColor: index % 2 === 0 ? "#f5f5f5" : "white",
                    boxShadow: "0px 2px 5px rgba(255, 255, 255, 0.7)",
                    borderRadius: "4px",
                    marginBottom: 1,
                  }}
                >
                  <Typography variant="body1">
                    Invoice No: {row.invoice_number || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Date:{" "}
                    {row.date
                      ? new Date(row.date).toISOString().split("T")[0]
                      : "N/A"}
                  </Typography>

                  <Typography variant="body2">
                    Trans Type: {row.payment_mode || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Bank Type: {row.account_name || "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    Amount: {row.amount || "N/A"}
                  </Typography>
                  {/* <Typography variant="body2">
                    Total Amount: {row.total_amount || "N/A"}
                  </Typography> */}
                </Box>
              ))
          ) : (
            <Typography align="center">No Records to show</Typography>
          )}
        </Box>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
