import React, { useEffect, useState } from "react";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import CustHeader from '../../../Components/header/Header/CustHeader'
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
import QRCodeCanvas from 'qrcode.react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Stack,
    Typography,
    Button,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from "react-router-dom";
import { addItem, removeItem } from '../../../redux/reducers/Cart'
import Carousel from 'react-material-ui-carousel'
import ReactReadMoreReadLess from "react-read-more-read-less";
import AlertDialog from "../../../Components/AlertDialog/AlertDialog";
import Calculation from "../../../utility/Calculations";

const ProductDetails = () => {
    //#region code
    const { currency } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open);
    const navigate = useNavigate();
    const location = useLocation();
    const [images, setImages] = useState([]);
    const [itemDetails, setItemDetails] = useState('');
    const dispatch = useDispatch()
    const cartValue = useSelector((state) => state.cart.cartValue)
    const [isInCart, setIsInCart] = useState(false)
    const [formData, setFormData] = useState({})
    const [qrCode, setQrCode] = useState('');
    const [image, setImage] = useState(
        `https://dummyimage.com/400x400/7e8eff/ffffff&text=Product+Image`
    )
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((state) => ({
            ...state,
            [name]: value
        }));
        if (name === 'size_id') {
            const selectedSize = itemDetails.size.filter((item) => (value === item.id));
            setFormData((state) => ({
                ...state,
                item_size: selectedSize[0].size
            }));
        }
    }

    useEffect(() => {
        const proImg = location.state.image;
        const proData = location.state.data;
        setImages(proImg);
        setItemDetails(proData);
        const qrCodeData = `http://demo.d-crm.in/product-info?itemId=${proData.id}&uuid=${proData.qr_code}`
        setQrCode(qrCodeData);
    }, []);

    useEffect(() => {
        isItemInCart()
    }, [cartValue]);

    // const fetchItemSize = async () => {
    //     try {
    //         const result = await configServ.getItemSize({ "item_id": data.id })
    //         const filtered = result.filter((item) => {
    //             return item.size !== 'Primary'
    //         })
    //         // console.log(filtered)
    //         setItemSize(filtered)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    const manageCart = () => {
        const dataToSend = {
            ...formData,
            id: itemDetails.id,
            company_id: itemDetails.company_id,
            itemname: itemDetails.item_name,
            hsn_code: itemDetails.hsncode,
            Taxrate: itemDetails.tax_rate,
            discount: itemDetails.discount,
            rate: itemDetails.rate_unit,
            item_code: itemDetails.item_code,
            issizeAdded: Boolean(itemDetails.issizeAdded),
        }
        if (!isInCart) {
            if (Boolean(itemDetails.issizeAdded)) {
                if (formData.item_size) {
                    dispatch(addItem(dataToSend))
                } else {
                    handleOpenDialog('Please select a size for the item')
                }
            } else {
                dispatch(addItem(dataToSend))
            }
        } else {
            dispatch(removeItem(itemDetails.id))
        }
    }

    const isItemInCart = () => {
        const inCart = cartValue.find((item) => {
            return item.id === location.state.data.id
            // console.log(item.id === location.state.data.id)
        })
        if (inCart !== undefined) {
            setIsInCart(true)
            return cartValue
        } else {
            setIsInCart(false)
            return cartValue
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Grid container alignItems={'center'} spacing={1}>
                <Grid item xs={12} md={6}>
                    <Grid container alignItems={'center'} sx={{ p: 3 }}>
                        <Carousel
                            autoPlay infiniteLoop transitionTime={500} showThumbs={false}
                            sx={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 2
                            }}
                            NextIcon={<NavigateNextOutlinedIcon />}
                            PrevIcon={<ArrowBackIosNewOutlinedIcon />}
                        >
                            {images.length === 0 ?
                                <Box p={3}>
                                    <Card>
                                        <CardMedia
                                            sx={{ p: 3 }}
                                            component="img"
                                            alt='product'
                                            height='400px'
                                            image={image}
                                            style={{ objectFit: 'contain' }}
                                        />
                                    </Card>
                                </Box> :
                                images.map((item) => (
                                    <Box key={item.id} p={3}>
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                alt='product'
                                                height='400px'
                                                image={`data:image/png;base64,${item.image}`}
                                                style={{ objectFit: 'contain' }}
                                            />
                                        </Card>
                                    </Box>
                                ))}
                        </Carousel>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container alignItems={'center'} sx={{ background: '#e3f3fc', p: 3 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6" color={'text.secondary'}><b>PRICE DETAILS</b></Typography>
                        </Grid>
                        <Divider sx={{ backgroundColor: '#33333377', width: '100%', my: 1 }} />
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Item Name</Typography>
                                <Typography variant="h6"><b>{itemDetails.item_name}</b></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Brand</Typography>
                                <Typography variant="h6"><b>{itemDetails.brand}</b></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Category</Typography>
                                <Typography variant="h6"><b>{itemDetails.category}</b></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Group</Typography>
                                <Typography variant="h6"><b>{itemDetails.group_name}</b></Typography>
                            </Box>
                        </Grid>
                        {
                            itemDetails.description &&
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6">
                                        Description
                                    </Typography>
                                    <Typography variant="caption" color={'text.secondary'} fontSize={'14px'} ml={2}>
                                        <ReactReadMoreReadLess
                                            charLimit={50}
                                            readMoreText={"...Read more ▼"}
                                            readLessText={"Read less ▲"}
                                        >
                                            {itemDetails.description}
                                        </ReactReadMoreReadLess>
                                    </Typography>
                                </Box>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Price</Typography>
                                <Typography variant="h6"><b>₹{itemDetails.rate_unit}</b></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Discount</Typography>
                                <Typography variant="h6">-<b>₹{Calculation.discountAmount(itemDetails.rate_unit, itemDetails.discount)}</b></Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">GST</Typography>
                                <Typography variant="h6">+<b>₹{Calculation.taxAmount(itemDetails.rate_unit, itemDetails.discount, itemDetails.tax_rate)}</b></Typography>
                            </Box>
                        </Grid>
                        <Divider sx={{ backgroundColor: '#33333377', width: '100%', my: 1 }} />
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <Typography variant="h6">Total Amount</Typography>
                                <Typography variant="h6"><b>₹{Calculation.netAmount(itemDetails.rate_unit, itemDetails.discount, itemDetails.tax_rate)}</b></Typography>
                            </Box>
                        </Grid>
                        <Divider sx={{ backgroundColor: '#33333377', width: '100%', my: 1 }} />
                        {Boolean(itemDetails.issizeAdded) &&
                            <FormControl
                                fullWidth
                                size='small'
                                sx={{
                                    width: {
                                        md: '45%',
                                        xs: '100%'
                                    },
                                    // height: '1.7rem',
                                    fontSize: {
                                        xs: '0.35rem',
                                        sm: '0.45rem',
                                        md: '0.65rem'
                                    },
                                }}
                            >
                                <InputLabel >Size</InputLabel>
                                <Select
                                    label="Size"
                                    name='size_id'
                                    // sx={{
                                    //     height: '100%'
                                    // }}
                                    onChange={handleChange}
                                    value={formData.size_id || ''}
                                >
                                    {itemDetails.size.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>{item.size}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        }
                        <Grid container alignItems={'center'} spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Button
                                    fullWidth
                                    size="small"
                                    onClick={manageCart}
                                    color={isInCart ? 'error' : 'primary'}
                                    variant={'contained'}
                                    sx={{
                                        mx: 1,
                                        // flex: 1,
                                        // ...(!isInCart && { backgroundColor: '#1976d211' }),
                                        // ...(isInCart && { backgroundColor: '#333', ":hover": { backgroundColor: '#333333dd' } }),
                                        // fontSize: { xs: '0.75rem', sm: '0.75rem', md: '0.65rem' }
                                    }}
                                >
                                    {isInCart ? 'Remove' : 'Add to Cart'}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    fullWidth
                                    size="small"
                                    onClick={() => { navigate('/product') }}
                                    color="secondary"
                                    variant='contained'
                                    sx={{
                                        mx: 1,
                                        // color: '#333',
                                        // borderColor: '#333',
                                        // backgroundColor: '#33333311',
                                        // flex: 1,
                                        // fontSize: {
                                        //     xs: '0.75rem',
                                        //     sm: '0.75rem',
                                        //     md: '0.65rem'
                                        // }
                                    }}
                                >
                                    View All Products
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            itemDetails.qr_code !== null && itemDetails.qr_code !== undefined &&
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <QRCodeCanvas value={qrCode} />
                                {/* <img
                                    alt='Not Found'
                                    src={itemDetails.qr_code}
                                    style={{ marginRight: 30 }}
                                    width={200}
                                /> */}
                            </div>
                        }
                    </Grid>
                </Grid>
            </Grid>
            <br />
        </>
    )
};
export default ProductDetails;